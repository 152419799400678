.login{
  background-image: url("../../assets/Images/login.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.login-content{
  background-color: white;
  width: 400px;
  height: fit-content;
  padding: 1rem;
  
}