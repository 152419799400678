.join-form-container {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #000;
  background-color: #fff;
  height: auto;
}
.Join-form {
  margin-top: 2rem;
  box-sizing: border-box;
}
.join-container{
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  box-sizing: border-box;
}
.as{
  color: #fff;
  padding: 1rem;
}
.dropdown {
  width: 250px;
}
.mainImageContainer{
  display: flex;
  justify-content: end;

  
}
.imageContainer {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 1rem;
  z-index: 99;
  cursor: pointer;
 
}
.textDropdown{
  background-color: #fff;
  width: 100%;
}
.button-container {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  left: 38rem;
  opacity: 0.9;
  z-index: -1;
  justify-content: space-between;
}
.buttons {
  width: 100px;
  height: 40px;
  border-radius: 8px;
  background-color:  #b9b8b8;
  border-color: #fff;
  color: #fff;
  margin-right: 1rem;
}
.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
input {
  display: block;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 240px;
  height: 50px;
  border-radius: 8px;
}
select {
  margin-bottom: 1rem;
  height: 50px;
  border-radius: 8px;
}
.where {
  display: flex;
  flex-direction: column;
}
.where img {
  align-items: flex-end;
}
.join-buttons{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.dropdown-container{
  width: 100%;
}
.addDropDown{
  display: flex;
  flex-direction: row;
}
.autocomplete{
  width: 200%;
}
.where-container {
  margin-right: 10px;
  display: flex;
  justify-content: space-evenly;
}
.join-actions{
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
}
.join-actions:hover{
  background-color:#f8f7f7;
}
