.formContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  width: auto;
  padding: 1rem;
}
.result-form {
  align-items: center;
}
.dropdown {
  width: 250px;
}

.result-image{
    position: absolute;
    cursor: pointer;
    top: 50px;
    right: -50px;
}

.Button-container {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  left: 25px;
  opacity: 0.9;
  z-index: -1;
  justify-content: space-between;
}

.Button-container button {
  margin-top: 2px;
  height: 3rem;
  width: 10rem;
  background: #f0ebd0;
  border: none;
  border-radius: 1rem;
  cursor: pointer;
}

.button {
  width: 100px;
  height: 40px;
  border-radius: 8px;
  background-color: #4472c4;
  border-color: #fff;
  color: #fff;
  margin-right: 1rem;
}
.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.img {
  justify-content: flex-end;
  margin-left: 15px;
}

.result-node-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
