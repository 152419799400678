.header {
  background-color: #051881;
  display: flex;
  flex-direction: row;
  color: aliceblue;
  gap: 3rem;
}

.title {
  font-size: 1.5rem;
}