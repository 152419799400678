.form-container {
  padding: 1rem;
  border-radius: 8px;
}

.image-container {
  display: flex;
  align-items: center;
  position: relative;
  margin-right: -2.8rem;
  z-index: 99;
}

.butt-container {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  left: 50px;
  opacity: 0.9;
  z-index: -1;
  justify-content: space-between;
}

.butt-container button {
  margin-top: 2px;
  height: 3rem;
  width: 10rem;
  background: rgba(68, 114, 196, 0.25);
  border: none;
  border-radius: 1rem;
  cursor: pointer;
}

.button {
  width: 100px;
  height: 40px;
  border-radius: 8px;
  background-color: #4472c4;
  border-color: #fff;
  color: #fff;
  margin-right: 1rem;
}

.form {
  position: relative;
}

.image-container {
  position: absolute;
  cursor: pointer;
  bottom: 100px;
  right: -20px;
}

.mantine-Textarea-root,
.mantine-Select-root {
  margin: 0.7rem 0;
  color: #fff;
}

.mantine-Switch-label {
  color: #fff;
}

.toolbar {
  display: flex;
  gap: 0.8rem;
}

.mantine-InputWrapper-label {
  color: #fff;
  margin-bottom: 5px;
}